
export const DPR = ["","1.5","2"]

export const VIEWPORTS = {
  'sm': 768,
  'md': 1024,
  'lg': 1280,
  'xs': 640,
  'xl': 1380
} as const

export type Viewport = keyof typeof VIEWPORTS